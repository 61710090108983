.my-team-table {
border-collapse: separate;
border-spacing: 0;
border: 3px solid #000;
border-radius: 15px;
-moz-border-radius: 15px;
-webkit-border-radius: 15px;
}

.my-team-table tr:first-of-type {
  border-top-left-radius: 15px;
}

.my-team-table tr:first-of-type td:last-of-type {
  border-top-right-radius: 15px;
}

.my-team-table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 15px;
}

.my-team-table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 15px;
}
.team-slot {
    border-top:none;
    text-align:center;
}
.my-team-table td {
  height:50px;
}
.team-sub-header {
  font-weight:400;
  border-bottom: 2px solid #e5e5e5;
}
.team-add-player {
  background-color: transparent;
  border: 2px solid var(--flow-blue);
  color: var(--flow-blue);
  font-weight: 500;
  border-radius: 5px;
  width:50px;
  transition: all .2s ease;
}
.team-add-player:hover {
  background-color: var(--flow-blue);
  color:#fff;
}
.drop-player {
  background-color: transparent;
  border: 2px solid var(--danger);
  color: var(--danger);
  font-weight: 500;
  border-radius: 5px;
  width:50px;
  transition: all .2s ease;
}
.drop-player:hover {
  background-color: var(--danger);
  color:#fff;
}
.team-stats-select {
  height:40px;
  color:#6e6d7a;
  background:#f3f3f4;
  box-shadow: 0px 0px 0px 1px #e7e7e9 inset;
  border-radius:8px;
  outline:none;
  padding-left: 6px;
  font-weight:500;
  font-size:15px;
  margin-left:20px;
  margin-right:20px;
}
.team-drop-player {
  height:40px;
  color:var(--danger);
  border-radius:8px;
  font-weight:500;
  font-size:15px;
  margin-left:20px;
  border: 2px solid var(--danger);
}
.team-stats-select:hover {
  border:none;
  box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
  color:#0d0c22;
}
@media only screen and (min-width : 1025px) {
  .team-stats-select {
      position: absolute;
      left: 1000px;
  }
  .team-drop-player {
      position: absolute;
      left: 890px;
      
  }
}
.my-team-header-collapse {
  display:none;
}
@media only screen and (max-width : 587px) {
  .my-team-header {
    display:none;

  }

  .my-team-header-collapse {
    display:block
  }
  .team-drop-player {
    margin-left:0px;
  }
}
.player-bench-selected {
  background-color: rgba(153, 204, 255, 0.5);
  border-radius:15px;
  transition: all .15s ease;
}
.dropping-players {
  background-color:var(--danger);
  color:#fff;
  transition: all .3s ease;
}
.team-settings-name {
  background-color:#f3f3f4;
  border:2px solid #f3f3f4;
  height:40px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  border-radius: 8px;
  padding-left: 16px;
  transition:all 200ms ease;
  margin-bottom:20px;
  width:250px;
}
.team-settings-name:hover {
  background-color: #fff;
  box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
}
.team-settings-name:focus {
  background-color: #fff;
  box-shadow: 0px 0px 0px 0px #e7e7e9,0px 0px 0px 4px rgba(153, 204, 255,.5);
  border:2px solid var(--flow-blue);
}
.team-settings-logo {
  width:70px;
  margin-bottom:-3px;
}
.team-settings-logo-wrapper {
  margin-right:20px;
  
}
.logo-selected {
  border: 5px solid var(--flow-blue);
}