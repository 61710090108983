button {
    outline: none;
}
a {
    text-decoration: none;
}
.home-nav-logo {
    height: 60px;
}
.home-nav {
    display: flex;
    align-items: center;
    height: 70px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    transition:box-shadow .2s ease 0s,background-color .2s ease 0s;
}
.opaque {
    background-color: hsla(0,0%,100%,0.8);
    backdrop-filter:saturate(180%) blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
}
.home-nav-brand {
    display: flex;
    align-items: center;
}
.home-nav-link {
    margin-right: 15px;
    font-family: 'Inter';
    color: #666;
    font-size: 15px;
    transition: color .2s linear;
}
.home-nav-links {
    display: flex;
    align-items: center;
}
.nav-socials {
    display: flex;
    align-items: center;
    padding-top:5px;
    display: none;
    background-color: transparent;
}
.nav-socials.show {
    display: inline;
}
.home-nav-link:hover {
    color: #000;
}
.home-nav-brand-name {
    display: inline;
    margin:0;
    color: var(--flow-blue);
    font-family: 'BigJohn';
    padding-top: 5px;
    font-size: 20px;
}
.sign-up {
    background-color: #000;
    height: 35px;
    width: 80px;
    border: none;
    border-radius: 5px;
    color: #fff;
    transition: all .2s linear;
    font-weight: 500;
}
.sign-up:hover {
    color: #000;
    border: solid 1px #000;
    background-color: transparent;
}
.section-process {
    --gradient-color-1: #007CF0;
    --gradient-color-2: #00DFD8;
    --gradient-color-3: #7928CA;
    --gradient-color-4: #FF0080;
    --gradient-color-5: #FF4D4D;
    --gradient-color-6: #F9CB28;
}
.section-process .process-steps-container .process-step-container .process-step-title-container {
    margin-top: -16px;
    position: relative;
}
.section-process .process-steps-container .process-step-container .process-step-title-container .process-step-title, .section-process .process-steps-container .process-step-container .process-step-title-container .process-step-title-overlay {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 100px;
    font-weight: 900;
    margin: 0;
    line-height: 1.2em;
    letter-spacing: -5px;
    line-height: 110px;

}
.section-process .process-steps-container .process-step-container .process-step-title-container .process-step-title-overlay {
    position: absolute;
    top: 0;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    width: auto;
    padding-right: 30px;
    height: 100%;
    opacity: 1;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}
.section-process .process-steps-container .process-step-container.process-step-1 .process-step-title-overlay {
    background-image: linear-gradient(90deg, var(--gradient-color-1), var(--gradient-color-2));
    animation: animated-gradient-title-1 8s infinite;
    transform: translate(-46.2%, -50%);
}
.section-process .process-steps-container .process-step-container.process-step-2 .process-step-title-overlay {
    background-image: linear-gradient(90deg, var(--gradient-color-3), var(--gradient-color-4));
    animation: animated-gradient-title-2 8s infinite;
    transform: translate(-46.65%, -50%);
}
.section-process .process-steps-container .process-step-container.process-step-3 .process-step-title-overlay {
    background-image: linear-gradient(90deg, var(--gradient-color-5), var(--gradient-color-6));
    animation: animated-gradient-title-3 8s infinite;
    transform: translate(-43.5%, -50%);
}
@keyframes animated-gradient-title-1 {
    0%, 16.667%, 100% {
        opacity: 1;
   }
    33.333%, 83.333% {
        opacity: 0;
   }
}
@keyframes animated-gradient-title-2 {
    0%, 16.667%, 66.667%, 100% {
        opacity: 0;
   }
    33.333%, 50% {
        opacity: 1;
   }
}
@keyframes animated-gradient-title-3 {
    0%, 50%, 100% {
        opacity: 0;
   }
    66.667%, 83.333% {
        opacity: 1;
   }
}
.cta {
    margin-top: 60px;
}
.cta-btn {
    height: 50px;
    width: 200px;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 16px;
    padding-top: 2px;
    border-radius: 5px;
    font-family: 'Inter';
    font-weight: 500;
    transition: all .2s linear;
}
.get-started {
    color: #fff;
    background-color: #000;
    border: none;
    margin-bottom: 10px;
}
.login {
    border: solid 1px #eaeaea;
    background-color: transparent;
    color: #666;
}
.get-started:hover {
    border: solid 1px #000;
    background-color: transparent;
    color: #000;
}
.login:hover {
    color: #000;
    border-color: #000000;
}
.section-process {
    margin-top: 15px;
}
@media only screen and (max-width: 500px) {
    .home-nav-link {
        display: none;
    }
}
.post-cta-callout {
    font-family: 'Inter';
    font-size: 20px;
    margin-top: 40px;
    color: #666666;
}
.home-explore {
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 4px;
}
.daily-brand {
    font-family: 'HelveticaNeue';
    font-size: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: 600;
}
.gives-brand {
    font-size: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-weight: 600;
    font-family: 'Recoleta';
    color: #000 !important;
}
.headline {
    color: #000 !important;
    font-size: 60px;
    font-weight: 700;
}
.paragraph {
    font-weight: 400;
    font-size: 17px;
    color: #666;
    line-height: 26px;
    padding: 5px;
}
.side-bar {
    margin-bottom: 50px;
}
.more-details {
    color: #0A72EF !important;
    font-size: 22px;
    text-decoration: underline;
    font-weight: 400;
    transition: opacity .1s ease-in-out;
}
.more-details:hover {
    opacity: .7;
}
.daily-gets-started {
    background-color: #0079F2;
    color: #fff !important;
    height: 50px;
    width: 200px;
    font-size: 16px;
    border-radius:5px;
    border:none;
    font-family: 'Inter';
    font-weight: 500;
    transition: all .2s linear;
}
.daily-gets-started:hover {
    border: 1px #0079F2 solid;
    background-color: transparent;
    color: #0079F2 !important;
}
.pll-gets-started {
    background-color: #99ccff;
    color: #fff !important;
    height: 50px;
    width: 200px;
    font-size: 16px;
    border-radius:5px;
    border:none;
    font-family: 'Inter';
    font-weight: 500;
    transition: all .2s linear;
}
.pll-gets-started:hover {
    border: 1px #99ccff solid;
    background-color: transparent;
    color: #99ccff !important;
}
.home-footer {
    background-color: #fafafa;
    margin-top: 50px;
    height: 300px;
}
.home-footer-col {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.home-footer-col-header {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    color: #000;
}
.home-footer-col > a {
    color: #666 !important;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
}
.footer-link {
    transition: all .2s linear;
}
.footer-link:hover {
    color: #000;
}
.grow-the-flow {
    color: var(--flow-blue);
    font-size: 40px;
    font-weight: 600;
}